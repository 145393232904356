import React from 'react'
import Constrain from 'components/Constrain'
import './container.scss'

export default function Container({ children }) {
    return (
        <section className='container'>
            <Constrain>
                    { children }
            </Constrain>
        </section>
    )
}