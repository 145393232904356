import React from 'react'

import './video.scss'

import frameLarge from './frameLarge.png'
import frameSmall from './frameSmall.png'

export default function Video({ poster, src, type = 'video/mp4', mobile }) {
    return (
      <div className='videoPadding'>
        <div className={`videoContainer ${ mobile ? 'videoContainer--mobile' : 'videoContainer--desktop' }`}>
          <video poster={ poster } muted loop playsInline preload="none" autoPlay>
            <source src={ src } type={ type }/>
          </video>
          <img className='deviceFrame deviceFrame--large' src={ frameLarge } alt='Device frame large' />
        </div>
      </div>
    )
}

export function DualVideoContainer({ desktop, mobile, type = 'video/mp4' }) {

  return (
    <div className='videoPadding'>
      <div className='dualVideoContainer'>
          <div className={`videoContainer videoContainer--desktop`}>
            <video poster={ desktop.poster } muted loop playsInline preload="none" autoPlay>
              <source src={ desktop.src } type={ type }/>
            </video>
            <img className='deviceFrame deviceFrame--large' src={ frameLarge } alt='Device frame large' />
          </div>
          
          <div className={`videoContainer videoContainer--mobile }`}>
            <video poster={ mobile.poster } muted loop playsInline preload="none" autoPlay>
              <source src={ mobile.src } type={ type }/>
            </video>
            <img className='deviceFrame deviceFrame--small' src={ frameSmall } alt='Device frame small' />
          </div>
      </div>
    </div>
  )

}