import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import { DualVideoContainer } from 'components/Video'

import Container from 'components/Container'

// import image from 'images/shop-management.png'
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import windows from 'images/icons/001-windows.svg'
import apple from 'images/icons/002-apple-logo.svg'
import android from 'images/icons/003-android-logo.svg'

import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

import Footer from "components/Footer"
import Header from "components/Header"

const VIDEO = 'desktop-device-link'
const image = require(`images/${ VIDEO }.png`)

export default function Home() {

  const { t } = useTranslation()

  const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={ t('Device management') } 
        subtitle={ t('Use all the devices you want and share them between team members') }
        dark 
      />
      <Constrain>

        <DualVideoContainer
            desktop={{
                poster: image,
                src: `https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/${ VIDEO }.mp4`
            }}
            mobile={{
                poster: image,
                src: `https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/mobile-device-link.mp4`
            }}
        />

      </Constrain>

          <div className='deviceIcons'>
            <Constrain>

            <img src={ windows } alt='Windows' />
            <img src={ apple } alt='Apple / iOS' />
            <img src={ android } alt='Android' />

          </Constrain>
        </div>

      <Container>

        <h1>{ t(`Use any device you want`) }</h1>

        <p>
            { t(`{{ siteTitle }} is a web app that works on all of your devices. Add and remove devices from your account whenever you need.`, { siteTitle: title }) }
        </p>

        <h2>{ t(`Link a device to your account`) }</h2>

        <p>
            <Trans>Devices are linked to your account as either an <strong>Administrator</strong>, <strong>Service Advisor</strong>, or <strong>Technician</strong> device.</Trans>
        </p>
        <p>
            { t(`From a linked device, you and your team members can log in simply by clicking a user and entering the pin code.`) }
        </p>

        <h2>{ t(`Remotely disable devices`) }</h2>

        <p>
            { t(`Lost a device? No problem, easily disable it from anywhere.`) }
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
